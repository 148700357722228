<template>
  <div>
    <display></display>
  </div>
</template>

<script>
import Display from "../components/illustration/display.vue";
export default {
  components: {
    Display,
  },
};
</script>

<style>
</style>